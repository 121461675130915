<template>
    <div :id="`row-id-${block.id}`" style="position: relative">
        <SelectedActionMenu ref="selectedActionMenuRef" v-if="showActionMenuOpen" :position="actionMenuPosition" :blockId="block.id"/>

        <CommandMenu 
        :viewMenu="commandMenuVar" 
        @update:viewMenu="commandMenuVar = $event"
        ref="editableMenu" 
        :key="block.tag" 
        @select="updateTag">
            <div :class="`block-content ${block.tag} ${block.section == 'title' ? 'project-title' : ''}`">
                <div class="handle-order"><icon-reorder /></div>
                <component 
                ref="editable" 
                contenteditable 
                v-on="listeners" 
                style="width: 100%"
                :block="block"
                :placeholder="commandItem ? commandItem.label : _placeholder" 
                :id="`block-${block.id}`" 
                :is="['h1', 'h2', 'h3', 'p'].includes(block.tag) ? 'SimpleText' : block.tag"
                @keydown="onKeyDown"
                @focus="focused = true"
                @blur="focused = false"
                @update-task="handleDebounceUpdateTaskFromComponent"
                @updateProject="$emit('update-entire-block')"
                @deleteBlock="(e) => { $emit('delete-block', e) }"
                @paste="pasteOnlyText"
                v-highlight="handleMouseUpToOpenActionMenu"
                />
            </div>
        </CommandMenu>
    </div>
</template>

<script>
// Components
import domMixin from "@/mixins/domMixin";
import { debounce } from "debounce";

import CommandMenu from "./CommandMenu";
import SelectedActionMenu from "./SelectedActionMenu";
import Task from "./Tags/Task";
import File from "./Tags/File";
import Project from "./Tags/ChooseProject";
import SimpleText from "./Tags/Text";
import IconReorder from "@/components/Icons/Reorder";

export default {
	mixins: [domMixin],
    components: { 
        CommandMenu,
        Task,
        File,
        Project,
        SimpleText,
        SelectedActionMenu,
        IconReorder
    },
    props: {
        block: Object,
        project: Object,
    },
    watch: {
        block: {
            handler(val, oldVal) {
                this.html = val.html ? val.html : '';

                let editable = '';
                let startPosition = 0;
                let selection = window.getSelection();
                var needSetCaret = selection.rangeCount > 0 ? true : false;

                if(needSetCaret) {
                    let range = selection.getRangeAt(0);
                    startPosition = range.startOffset;
                }
                
                this.$nextTick(() => {
                    if(this.block.tag == 'task') {
                        editable = this.$refs.editable.$refs.editableInputTask;
                        this.$refs.editable.$refs.editableInputTask.innerHTML = this.html;
                    } else if(['h1', 'h2', 'h3', 'p'].includes(this.block.tag)){
                        editable = this.$refs.editable.$refs.editableBlock;
                        this.$refs.editable.$refs.editableBlock.innerHTML = this.html;
                    } else {
                        editable = this.$refs.editable;
                        this.$refs.editable.innerHTML = this.html;
                    }
                            
                    if(needSetCaret && (selection.anchorNode === editable || selection.focusNode === editable)) {
                        this.setCaretToPosition(editable, startPosition);
                    }
                });
            },
        }
    },
    data() {
        return {
            htmlBackup: null,
            previousKey: null,
            commandMenuVar: false,
            commandItem: null,
            focused: false,
            html: null,
            showActionMenuOpen: false,
            actionMenuPosition: { x: 0, y: 0 }
        };
    },
    created() { 
        this.debouncedUpdateTask = debounce( (html) => { this.updateTask(html); }, 1000, false);
        this.debouncedUpdateTaskInstant = debounce( (html) => { this.updateTask(html); }, 1000, true);
    },
    beforeDestroy() {
        this.$root.$off("convert_text_to_link");
    },
    mounted() {
        this.html = this.block.html ? this.block.html : '';
        if(this.block.tag == 'task') {
            this.$refs.editable.$refs.editableInputTask.innerHTML = this.html;
        } else if(['h1', 'h2', 'h3', 'p'].includes(this.block.tag)){
            this.$refs.editable.$refs.editableBlock.innerHTML = this.html;
        } else {
            this.$refs.editable.innerHTML = this.html;
        }

        this.$root.$on('convert_text_to_link', this.convertToLink)
    },
    computed: {
        listeners() {
            return {
                input: this.onInput,
            };
        },
        _placeholder() {
            if (this.block.forcePlaceholder || this.focused) {
                return this.block.placeholder || "Type '/' for commands";
            } else {
                return "";
            }
        },
    },
    directives: {
        highlight: {
            bind(el, binding) {
                const onMouseUp = () => {
                    const selection = window.getSelection().toString()
                    if (selection) {
                        if (typeof binding.value === 'function') {
                            binding.value(selection)
                        } else {
                            console.error('v-highlight value must be a function')
                        }
                    }
                }
                el.__onMouseUp__ = onMouseUp
                el.addEventListener('mouseup', onMouseUp)
            },
            unbind(el) {
                el.removeEventListener('mouseup', el.__onMouseUp__)
                delete el.__onMouseUp__
            },
        },
    },
    methods: {
        handleMouseUpToOpenActionMenu() {
            // return to push stage on serve
            return ;

            // remove the action for the moment 
            // when it passes from one selection to another, without clicking out
            this.closeActionMenu();

            var nodeElement = '';
            if(this.block.tag == 'task') {
                nodeElement = this.$refs.editable.$refs.editableInputTask;
            } else {
                nodeElement = this.$refs.editable.$refs.editableBlock;
            }

            const { selectionStart, selectionEnd } = this.getSelection(nodeElement);

            if (selectionStart !== selectionEnd) {
                this.openActionMenu(nodeElement);
            } 
        },
        openActionMenu() {
            const { x, y} = this.getCaretCoordinates(this.block.id);
            this.actionMenuPosition = { x: x, y: y };
            this.showActionMenuOpen = true;

            // Add listener asynchronously to avoid conflicts with
            // the double click of the text selection
            setTimeout(() => {
                document.addEventListener("click", this.closeActionMenu, false);
            }, 100);
        },
        closeActionMenu() {
            this.actionMenuPosition = { x: null, y: null };
            this.showActionMenuOpen = false;
            
            document.removeEventListener("click", this.closeActionMenu, false);
        },
        async updateTag(commandItem) {
            this.commandMenuVar = false;
            this.commandItem = commandItem;

            var newBlockObj = { ...this.block, tag: commandItem.tag, html: this.htmlBackup };
            
            // Store NEW project, linked project, and redirect.
            if(commandItem.id == 'add-project') {
                await axios.post('/projects/store')
                .then(({data}) => {
                    newBlockObj.slug = data.data.slug;
                    newBlockObj.project_id = data.data.id;
                    newBlockObj.html = data.data.name;

                    // Add new project to list filters project, to be able to select the option
                    var tempProject = [...this.$store.state.blocks.projects];
                    tempProject.push({ id: data.data.id, name: data.data.name, slug: data.data.slug });
                    this.$store.dispatch('blocks/setProjects', Object.freeze(tempProject));

                    this.$store.dispatch('blocks/editBlock', newBlockObj);

                    let newId = '';
                    const ids = this.$store.state.blocks.blocks.map(el => { return el.id });
                    newId = Math.max(...ids) + 1;

                    let postionToPush = this.$store.state.blocks.blocks.findIndex(el => el.id == this.block.id) + 1;

                    const newBlock = { id: newId, section: 'body', html: "", tag: 'p' };

                    this.$store.dispatch('blocks/addBlock', {block: newBlock, postionToPush});
                    
                    axios.post(`/projects/${this.project.slug}/sync`, { type: 'attach', linked_project_id: newBlockObj.project_id })
                })
                .finally(() => {
                    // with parameter instant true
                    this.$emit("update-entire-block", newBlockObj.slug);
                })

                return true;
            } else if(this.block.tag != newBlockObj.tag && newBlockObj.tag == 'task' && this.htmlBackup) {
                // Store NEW task.
                var formObj = {
                    name: this.htmlBackup ? this.htmlBackup : 'Unamed',
                    project_id: this.project.id
                }
    
                await axios.post('/tasks/store', formObj)
                .then(({data}) => {
                    newBlockObj.task_id = data.data.id;
                    newBlockObj.frequency = data.data.frequency;
                    newBlockObj.done = 0;
                    newBlockObj.date = null;
                })
                .finally(() => {
                    this.$store.dispatch('blocks/editBlock', newBlockObj);
                })
            } else {
                if(commandItem.tag == 'task') {
                    newBlockObj.responsible_id = '';
                    newBlockObj.priority = '';
                    newBlockObj.done = 0;
                    newBlockObj.date = '';
                }
                this.$store.dispatch('blocks/editBlock', newBlockObj);
            }

            this.$nextTick(() => {
                setTimeout(() => {
                    if(newBlockObj.tag == 'task') {
                        this.$refs.editable.$refs.editableInputTask.innerHTML = this.block.html;
                        this.setCaretToEnd(this.$refs.editable.$refs.editableInputTask);
                    } else if(newBlockObj.tag == 'project') {
                        this.$refs.editable.$refs.vms.$el.focus();
                    } else if(['h1', 'h2', 'h3', 'p'].includes(newBlockObj.tag)) { 
                        this.$refs.editable.$refs.editableBlock.innerHTML = this.block.html;
                        this.setCaretToEnd(this.$refs.editable.$refs.editableBlock);
                    } else {
                        this.$refs.editable.innerHTML = this.block.html;
                        this.setCaretToEnd(this.$refs.editable);
                    }
                }, 0);
            })

            if(['file', 'project'].includes(commandItem.tag)) {
                this.$emit("add-block", true);
            }

            this.$emit('updateWebSocket');
        },
        onInput(e) {
            e.preventDefault();
            if(!this.commandMenuVar) {
                var newHtml = e.target.innerHTML;
                this.$store.dispatch('blocks/editBlock', {
                    ...this.block,
                    html: newHtml
                });
                
                if(this.block.tag == 'task') {
                    this.debouncedUpdateTask(newHtml);
                } else {
                    this.$emit("update-entire-block");
                }
            }
        },
        onKeyDown(e) {
            if (this.commandMenuVar) {
                this.$refs.editableMenu.onKeyDown(e);
                return;
            }

            switch (e.key) {
                case "/":
                    if (!this.block.noCommands) {
                        this.htmlBackup = this.block.html;
                        this.commandMenuVar = true;
                    }
                    break;

                case "Enter":
                    if (this.previousKey != "Shift") {
                        e.preventDefault();
                        if(!this.block.noCommands) {
                            this.$emit("add-block");
                        } else {
                            const nextElement = this.getNextSibling(
                                document.getElementById(`block-${this.block.id}`),
                                ".block"
                            );

                            if (nextElement) {
                                this.setCaretToEnd(nextElement);
                            }
                        }
                    } else if(this.block.tag === 'task' || ( this.block.tag === 'h1' && this.block.section === 'title' ) ) {
                        e.preventDefault();
                    }
                    break;

                case "Backspace":
                    if (!this.block.html && !this.block.cannotDelete) {
                        e.preventDefault();

                        // In case, the user deleted the block, we need to cleanup all listeners
                        document.removeEventListener("click", this.closeActionMenu, false);

                        this.$emit("delete-block", e);
                    }
                    break;

                case "ArrowUp":
                    e.preventDefault();

                    const previousElement = this.getPreviousSibling(
                        document.getElementById(`block-${this.block.id}`),
                        ".block"
                    );

                    if (previousElement) {
                        this.setCaretToEnd(previousElement);
                    }
                    break;

                case "ArrowDown":
                    e.preventDefault();

                    const nextElement = this.getNextSibling(
                        document.getElementById(`block-${this.block.id}`),
                        ".block"
                    );

                    if (nextElement) {
                        this.setCaretToEnd(nextElement);
                    }
                    break;

                default:
                    break;
            }
            this.previousKey = e.key;
        },
        setCaretToEnd(element) {
            if(!( element.hasOwnProperty('file') || element.hasOwnProperty('project_id')) ){
                const range = document.createRange();
                const selection = window.getSelection();
                range.selectNodeContents(element);
                range.collapse(false);
                selection.removeAllRanges();
                selection.addRange(range);
                element.focus();
            }
        },
        setCaretToPosition(currentEditableBlock, startPosition){
            let selection = window.getSelection();

            if (currentEditableBlock.setSelectionRange) {
                currentEditableBlock.setSelectionRange(startPosition, startPosition);
            } else {
                selection.removeAllRanges();
                const newRange = new Range();
                if (currentEditableBlock.firstChild) {
                    newRange.setStart(currentEditableBlock.firstChild, startPosition);
                    newRange.setEnd(currentEditableBlock.firstChild, startPosition);
                } else {
                    newRange.setStart(currentEditableBlock, 0);
                    newRange.setEnd(currentEditableBlock, 0);
                }
                selection.addRange(newRange);
            }
        },
        updateTask(newHtml) {
            if(this.$store.getters['blocks/blockId'](this.block.id)) {
                var realTimeBlock = this.$store.getters['blocks/blockId'](this.block.id);

                var paramsQuery = {};

                var taskFormObj = {
                    name: newHtml ? newHtml.replace(/&nbsp;/g, " ") : 'Unamed' ,
                    project_id: this.project.id,
                    user_id: realTimeBlock.responsible_id ? realTimeBlock.responsible_id : '',
                    priority: realTimeBlock.priority ? realTimeBlock.priority : ''
                };

                if( realTimeBlock.frequency ) { 
                    paramsQuery.all = 1;
                } else { 
                    paramsQuery.current = 1;
                }

                // if task is log send Id
				// if(realTimeBlock.hasOwnProperty('task_log_id') && this.block.task_log_id ) { 
				// 	paramsQuery.task_log_id = realTimeBlock.task_log_id;
				// }

                if( realTimeBlock.date ) {
					taskFormObj.deadline = realTimeBlock.date;
                    if( !realTimeBlock.frequency ) {
                        taskFormObj.start_date = realTimeBlock.date;
					    taskFormObj.date = realTimeBlock.date;
                    }
                }
                
                if( realTimeBlock.task_id ) {
                    axios.post(`/tasks/${realTimeBlock.task_id}/update`, taskFormObj, { params: paramsQuery })
                    .then(() => {
                        this.$emit("update-entire-block");
                        if(taskFormObj.user_id) { this.$emit("checkNewUsersSelected", taskFormObj.user_id); }
                    })
                } else {
                    // Store Task
                    axios.post('/tasks/store', taskFormObj)
                    .then(({data}) => {
                        var newBlock = {
                            ...realTimeBlock,
                            task_id: data.data.id,
                            frequency: realTimeBlock.frequency ? realTimeBlock.frequency : null,
                            done: realTimeBlock.done ? realTimeBlock.done : null,
                            date: realTimeBlock.date ? realTimeBlock.date : null
                        };

                        this.$store.dispatch('blocks/editBlock', newBlock);
                    })
                    .finally(() => {
                        this.$emit("update-entire-block");
                        if(taskFormObj.user_id) { this.$emit("checkNewUsersSelected", taskFormObj.user_id); }
                    })
                }
            }
        },
        handleDebounceUpdateTaskFromComponent(instantUpdate = false) {
            if( instantUpdate ){ 
                this.debouncedUpdateTaskInstant(this.block.html);
            } else {
                this.debouncedUpdateTask(this.block.html);
            }
        },
        pasteOnlyText(event){
            event.preventDefault();

            let paste = (event.clipboardData || window.clipboardData).getData('text');
            const selection = window.getSelection();
            if (!selection.rangeCount) return;
            selection.deleteFromDocument();
            selection.getRangeAt(0).insertNode(document.createTextNode(paste));

            this.onInput(event);
        },
        convertToLink(data) { 
            if(data.blockId != this.block.id) { return; }

            var rangeSelected = data.selectionRange;

            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(rangeSelected);
            
            document.execCommand('createLink', true, data.linkUrl);

            // add attribute target _blank
            setTimeout(() => {
                const links = document.querySelector('.focus-page .content').getElementsByTagName('a');
                for (let i = 0; i < links.length; i++) {
                    if (links[i].href == data.linkUrl) {
                        links[i].setAttribute('target', '_blank');
                    }
                }

                // need an update, after add attribute target _blank
                // get the new innerHTML
                var newHtmlWithTarget = '';
                if(this.block.tag == 'task') {
                    newHtmlWithTarget = this.$refs.editable.$refs.editableInputTask.innerHTML;
                } else if(['h1', 'h2', 'h3', 'p'].includes(this.block.tag)){
                    newHtmlWithTarget = this.$refs.editable.$refs.editableBlock.innerHTML;
                } else {
                    newHtmlWithTarget = this.$refs.editable.innerHTML;
                }

                this.$store.dispatch('blocks/editBlock', {
                    ...this.block,
                    html: newHtmlWithTarget
                });
    
                this.$emit("update-entire-block");
    
                if(this.block.tag == 'task') {
                    this.debouncedUpdateTask(newHtmlWithTarget);
                }
                // END update, after add attribute target _blank
            }, 200)
        }
    }
};
</script>
    